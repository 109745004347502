import React from 'react'
import styles from './PrivacyChinese.module.css'
import Header from '../../components/Header'
import Text from '../../components/Text'
import { firstTextDataChinese, secondTextDataChinese, thirdTextDataChinese } from '../../utils/Data/Dummy'
import Footer from '../../components/Footer'
const PrivacyChinese = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.innerContainer}>
                <Header />
                <Text text={'隐私政策'} align={'left'} size={'h1'} color={'#fff'} style={{ fontWeight: 700 }} />
                {firstTextDataChinese.map((item, index) => {
                    return (
                        <>
                            <Text text={item.title} align={'left'} color={'#fff'} style={index === 0 ? { fontWeight: 700, fontSize: '40px' } : { fontWeight: 700, fontSize: '24px' }} />
                            <Text text={item.text} align={'left'} size={'note'} color={'rgba(198,201,216,.75)'} style={{ fontWeight: 400, lineHeight: 1.9, paddingLeft: '24px' }} />
                        </>
                    )
                })}
                {secondTextDataChinese.map((item, index) => {
                    return (
                        <>
                            {item.title ? <Text text={item.title} align={'left'} color={'#fff'} style={{ fontWeight: 700, fontSize: '24px' }} /> : null}
                            <Text text={item.subtitle} align={'left'} color={'#fff'} style={{ fontWeight: 700, fontSize: '18px' }} />
                            <Text text={item.text} align={'left'} size={'note'} color={'rgba(198,201,216,.75)'} style={{ fontWeight: 400, lineHeight: 1.9, paddingLeft: '24px' }} />
                        </>
                    )
                })}
                {thirdTextDataChinese.map((item, index) => {
                    return (
                        <>
                            {item.title ? <Text text={item.title} align={'left'} color={'#fff'} style={{ fontWeight: 700, fontSize: '18px' }} /> : null}
                            {item.subtitle ? <Text text={item.subtitle} align={'left'} color={'rgba(198, 201, 216, .75)'} size={'note'} style={{ lineHeight: 1.9, }} /> : null}
                            <Text text={item.text} align={'left'} size={'note'} color={'rgba(198,201,216,.75)'} style={index >= 2 ? { fontWeight: 400, lineHeight: 1.9 } : { fontWeight: 400, lineHeight: 1.9, paddingLeft: '24px' }} />
                        </>
                    )
                })}
                <Footer />
            </div>
        </div>
    )
}

export default PrivacyChinese