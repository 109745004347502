import React from 'react'
import PropTypes from 'prop-types'
import TextStyles from './Text.Styles'
import useWindowDimensions from '../../utils/hooks/useWindowDimensions'

const Text = props => {

  const styles = { ...TextStyles }
  const Dimension = useWindowDimensions()

  let Platfrom

  const textWeightValues = {
    light: 300,
    regular: 400,
    bold: 600,
    extraBold: 800,
  }

  const {
    text,
    textWeight,
    color,
    size,
    align,
    style
  } = props

  if (Dimension.width < 1022) {
    Platfrom = styles[size].mobile
  } else if (Dimension.width > 1440) {
    Platfrom = styles[size].desktop
  } else {
    Platfrom = styles[size].tablet
  }

  const alignHelper = {
    left: 'flex-start',
    right: 'flex-end',
    center: 'center',
    justify: 'flex-start',
  }

  const propsStyle = {
    color: color,
    justifyContent: alignHelper[align],
    fontWeight: textWeightValues[textWeight]
  }

  const allStyles = Object.assign({},
    styles.defaultStyles,
    propsStyle,
    Platfrom,
    style
  );


  return (
    <div style={allStyles}>{text}</div>
  )
}


Text.propTypes = {
  text: PropTypes.string,
  size: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'note', 'body', 'footnote']),
  color: PropTypes.string,
  textWeight: PropTypes.oneOf(['light', 'regular', 'bold', 'extraBold']),
  align: PropTypes.oneOf(['left', 'right', 'center', 'justify']),
  style: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
  ]),
}


Text.defaultProps = {
  size: 'body',
  weight: 'regular',
  align: 'center'
}


export default Text