import React from 'react'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Text from '../../components/Text'
import styles from './Plexus.module.css'
import { textData } from '../../utils/Data/Dummy'


const Plexus = () => {
    const handleEmailRedirect = () => {
        window.open('mailto:plexusvr@gmail.com')
    }



    return (
        <div className={styles.wrapper}>
            <div className={styles.innerContainer}>
                <Header />
                <Text text={'Privacy Policy pleXus'} align={'left'} size={'h1'} color={'#fff'} style={{ fontWeight: 700 }} />
                {
                    textData.map((item) => {
                        return (
                            <>
                                <Text text={item.title} align={'left'} size={'body'} color={'rgba(198,201,216,.75)'} style={{ fontWeight: 700 }} />
                                <Text text={item.text} align={'left'} size={'note'} color={'rgba(198,201,216,.75)'} style={{ fontWeight: 400, lineHeight: 1.9 }} />
                            </>
                        )
                    }
                    )
                }
                <Footer />
            </div>
        </div>
    )
}

export default Plexus