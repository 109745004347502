import React from 'react'
import Discord from '../../utils/images/Discord.svg'
import Insta from '../../utils/images/Insta.svg'
import Email from '../../utils/images/Email.svg'
import styles from './Footer.module.css'
const Footer = () => {

    const handleInstagramRedirect = () => {
        window.open('https://www.instagram.com/outerlynx/')
    }
    const handleEmailRedirect = () => {
        window.open('mailto:contact@outerlynx.com')
    }
    const handleDiscordRedirect = () => {
        window.open('https://discord.com/invite/U9tds44Vcv')
    }

    return (
        <div className={styles.footerWrapper}>
            <div className={styles.getInTouchText}>Let’s Get in Touch</div>
            <div className={styles.socialsContainer}>
                <img src={Insta} alt='Instagram icon redirect to our instagram Page!' className={styles.socialsIcon} onClick={handleInstagramRedirect} />
                <img src={Discord} alt='Discord icon redirect to our discord Page!' className={styles.socialsIcon} onClick={handleDiscordRedirect} />
                <img src={Email} alt='Email icon redirect to our email Page!' className={styles.socialsIcon} onClick={handleEmailRedirect} />
            </div>
            <div className={styles.getInTouchText} style={{ fontSize: '24px', fontWeight: 600, marginTop: 0 }}>Contact us</div>
            <div onClick={handleEmailRedirect} className={styles.emailText}>contact@outerlynx.com</div>
            <div className={styles.copyrightText}>Copyright 2023 All rights reserved</div>
        </div>
    )
}

export default Footer