import React from 'react'
import styles from './Header.module.css'
import Logo from '../../utils/images/Logo.png'
import Symbol from '../../utils/images/symbol.png'
import { useNavigate } from 'react-router-dom'

const Header = () => {
    const navigate = useNavigate()

    const handleRoutingHome = () => {
        navigate('/');
    }
    return (
        <div className={styles.headerContainer} onClick={handleRoutingHome}>
            <img src={Logo} alt='Outerlynx logo name!' className={styles.logoStyles} />
            <img src={Symbol} alt='Outerlynx logo!' className={styles.logoImageStyles} />
        </div>
    )
}

export default Header