import React from 'react'
import styles from './Outerlynx.module.css'
import Logo from '../../utils/images/Logo.png'
import Symbol from '../../utils/images/symbol.png'
import Discord from '../../utils/images/Discord.svg'
import Insta from '../../utils/images/Insta.svg'
import Email from '../../utils/images/Email.svg'
import leftBottom from '../../utils/images/leftBottom.webp'
import leftMid from '../../utils/images/leftMid.webp'
import leftTop from '../../utils/images/leftTop.webp'
import rightBottom from '../../utils/images/rightBottom.webp'
import rightMid from '../../utils/images/rightMid.webp'

const Outerlynx = () => {

    const handleInstagramRedirect = () => {
        window.open('https://www.instagram.com/outerlynx/')
    }
    const handleEmailRedirect = () => {
        window.open('mailto:contact@outerlynx.com')
    }
    const handleDiscordRedirect = () => {
        window.open('https://discord.com/invite/U9tds44Vcv')
    }

    const LightingComponent = () => {
        return (
            <>
                <img src={leftTop} alt='Outerlynx hype lighting!' className={styles.leftTop} />
                <img src={leftMid} alt='Outerlynx hype lighting!' className={styles.leftMid} />
                <img src={leftBottom} alt='Outerlynx hype lighting!' className={styles.leftBottom} />
                <img src={rightBottom} alt='Outerlynx hype lighting!' className={styles.rightBottom} />
                <img src={rightMid} alt='Outerlynx hype lighting!' className={styles.rightMid} />
            </>
        )
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.innerContainer}>
                <div className={styles.reverseContainerHeader}>
                    <img src={Logo} alt='Outerlynx logo name!' className={styles.logoStyles} />
                    <img src={Symbol} alt='Outerlynx logo!' className={styles.logoImageStyles} />
                </div>
                <div className={styles.comingSoonText}>COMING SOON</div>
                <div className={styles.reverseContainers}>
                    <div className={styles.innerContainerSocialsAndText}>
                        <div className={styles.getInTouchText}>Let’s Get in Touch</div>
                        <div className={styles.socialsContainer}>
                            <img src={Insta} alt='Instagram icon redirect to our instagram Page!' className={styles.socialsIcon} onClick={handleInstagramRedirect} />
                            <img src={Discord} alt='Discord icon redirect to our discord Page!' className={styles.socialsIcon} onClick={handleDiscordRedirect} />
                            <img src={Email} alt='Email icon redirect to our email Page!' className={styles.socialsIcon} onClick={handleEmailRedirect} />
                        </div>
                    </div>
                    <div className={styles.innerContainerContactAndEmail}>
                        <div className={styles.getInTouchText}>Contact us</div>
                        <div onClick={handleEmailRedirect} className={styles.emailText}>contact@outerlynx.com</div>
                    </div>
                </div>
                <LightingComponent />
            </div>
        </div>
    )
}

export default Outerlynx