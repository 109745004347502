import { BrowserRouter, Routes, Route } from "react-router-dom"
import './App.css';
import Outerlynx from './containers/Outerlynx';
import Privacy from './containers/Privacy';
import Plexus from './containers/Plexus';
import PrivacyChinese from './containers/PrivacyChinese';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route index element={<Outerlynx />} />
          <Route path="brisk-square/privacy" element={<Privacy />} />
          <Route path="plexus/privacy/" element={<Plexus />} />
          <Route path="brisk-square/privacy-chn/" element={<PrivacyChinese />} />
          <Route path="*" element={<Outerlynx />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
